import React from 'react'
import Layout from '../components/_App/layout'
import Seo from '../components/_App/seo'
import TopHeader from '../components/Common/TopHeader'
import Navbar from '../components/_App/Navbar'
import PageHeading from '../components/Common/PageHeading'
import Footer from '../components/_App/Footer'
import { graphql, Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { dayFormat, monthFormat, yearFormat } from '../helper/dateFormat'
import { getFileExtention, getScaledImage } from '../helper/utilities'

const ArticelTemplate = ({ data }) => {
  const { title, blogDetails, blogImg, authors, tags, publishedOn } = data.blog

  return (
    <Layout>
      <Seo
        title={title}
        description={blogDetails.substring(0, 200)}
        image={getScaledImage(blogImg.url, 300)}
        imageType={`image/${getFileExtention(blogImg.url)}`}
      />

      <TopHeader />

      <Navbar />

      <PageHeading pageTitle={title} />

      <div className="blog-details-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="blog-article">
                <div className="blog-article-img">
                  <img src={blogImg.url} alt="Blog" />
                  <div className="blog-article-tag">
                    <h3>{dayFormat(publishedOn)}</h3>
                    <span>{monthFormat(publishedOn)}</span>
                  </div>
                </div>

                <div className="blog-article-title">
                  <ul>
                    {authors &&
                      authors.map(author => {
                        return (
                          <li key={author.id}>
                            <i className="bx bxs-user" />
                            {author.name}
                          </li>
                        )
                      })}
                    {tags &&
                      tags.map(tag => {
                        return (
                          <li key={tag.id}>
                            <i className="bx bx-hash" />
                            {tag.title}
                          </li>
                        )
                      })}
                  </ul>
                </div>

                <div className="article-content">
                  <ReactMarkdown>{blogDetails}</ReactMarkdown>
                </div>

                <div className="blog-article-share">
                  <div className="row align-items-center">
                    <div className="col-lg-7 col-sm-7 col-md-7">
                      <div className="blog-tag">
                        <ul>
                          <li>Tags:</li>
                          {tags.map(tag => {
                            return (
                              <li key={tag.id}>
                                <i className="bx bx-hash" /> {tag.title}
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="side-bar-area">
                <div className="side-bar-widget">
                  <h3 className="title">Our Services</h3>
                  <div className="side-bar-categories">
                    <ul>
                      {data.services.nodes.map(service => {
                        return (
                          <li key={service.id}>
                            <div className="line-circle" />
                            <Link to={`/services/${service.slug}`}>{service.title}</Link>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>

                <div className="side-bar-widget">
                  <h3 className="title">Latest Blog</h3>
                  <div className="widget-popular-post">
                    {data.blogs.nodes.map(blog => {
                      return (
                        <article className="item" key={blog.slug}>
                          <Link to={`/blogs/${blog.slug}`} className="thumb">
                            <span className="full-image cover">
                              <img src={blog.blogImg.url} alt={blog.title} height="100%" />
                            </span>
                          </Link>
                          <div className="info">
                            <h4 className="title-text">
                              <Link to={`/blogs/${blog.slug}`}>{blog.title}</Link>
                            </h4>
                            <p>
                              {dayFormat(blog.publishedOn)} {monthFormat(blog.publishedOn)},{' '}
                              {yearFormat(blog.publishedOn)}
                            </p>
                          </div>
                        </article>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query GetSingleBlogDetails($slug: String) {
    blog: strapiBlog(slug: { eq: $slug }) {
      title
      blogDetails
      blogImg {
        url
      }
      authors {
        name
        slug
        id
      }
      tags {
        slug
        title
        id
      }
      publishedOn
    }
    services: allStrapiServices(limit: 7, sort: { fields: order }) {
      nodes {
        title
        slug
        id
      }
    }
    blogs: allStrapiBlog(
      limit: 5
      filter: { slug: { ne: $slug } }
      sort: { fields: publishedOn, order: DESC }
    ) {
      nodes {
        publishedOn
        slug
        title
        updated_at
        blogImg {
          url
        }
      }
    }
  }
`

export default ArticelTemplate
